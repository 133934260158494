@import "../../data//styles.css";

.resume-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.resume-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.resume-title {
	width: 100% !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
}

.resume-title-text {
	width: 100% !important;
	font-family: var(--secondary-font);
	font-size: 50px;
	color: var(--primary-color);
}

.resume-download-button {
}

.resume-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.resume-container {
	display: flex;
	flex-direction: column;
}

.resume-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
}

/* Card Styles */

.resume-card {
	mix-blend-mode: normal;
	border-radius: 20px;
	outline: 2px solid #f4f4f5;
}

.resume-card-container {
	padding: 30px;
	padding-bottom: 5px;
}

.resume-card-header {
	display: flex;
	align-items: center;
}

.resume-card-icon {
	font-size: 25px;
	/* color: var(--tertiary-color); */
}

.hr {
	width: 80%; /* Set the width to span the entire container */
	height: 1px; /* Adjust the height of the line */
	background: linear-gradient(
		to right,
		transparent,
		#6c757d,
		transparent
	); /* Faded gradient effect */
	margin: 5px 0; /* Add some margin for spacing */
}

.resume-card-title {
	color: var(--secondary-color);
	font-size: 20px;
	padding-left: 20px;
	/* font-weight: 650; */
}

.resume-card-body {
	padding-top: 20px;
	font-size: 15px;
}

.resume-works-body {
	margin-bottom: 10px;
}

.resume-work {
	display: flex;
	padding-bottom: 15px;
}

.resume-work-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.resume-work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -3px;
	color: var(--secondary-color);
}

.resume-work-subtitle {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 50px;
}

.resume-work-skills {
	font-size: 12px;
	font-style: italic;
	color: var(--secondary-color);
	margin-left: 50px;
}

.resume-work-duration {
	position: absolute;
	width: 350px;
	font-size: 12px;
	padding-top: 20px;
	text-align: right;
	color: var(--tertiary-color);
}

.resume-work-details {
	margin-left: 20px; /* Adding left margin */
	margin-bottom: 10px;
	border: 0.1px solid #ffffff; /* Adding a subtle border */
	/* Adding padding inside the box */
	border-radius: 15px; /* Adding rounded corners for a smoother look */
	transition: box-shadow 0.5s ease-in-out; /* Adding transition for smooth animation */
}

.resume-work-details:hover {
	border: 0.2px solid #ddd; /* Adding a subtle border */
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Adding a slight box shadow on hover */
}

.resume-work-details-list {
	list-style-type: disc; /* Using disc bullets for unordered list */
	padding-left: 30px; /* Adding padding for the list */
}

@media (max-width: 1024px) {
	.resume-title-text {
		width: 100% !important;
	}

	.resume-subtitle {
		width: 100% !important;
	}
}
