@import "../../../data/styles.css";

.card {
	mix-blend-mode: normal;
	border-radius: 20px;
	outline: 2px solid #f4f4f5;
}

.card-container {
	padding: 30px;
	padding-bottom: 5px;
}

.card-header {
	display: flex;
	align-items: center;
}

.card-icon {
	font-size: 25px;
	/* color: var(--tertiary-color); */
}

.hr {
	width: 80%; /* Set the width to span the entire container */
	height: 1px; /* Adjust the height of the line */
	background: linear-gradient(
		to right,
		transparent,
		#6c757d,
		transparent
	); /* Faded gradient effect */
	margin: 5px 0; /* Add some margin for spacing */
}

.card-title {
	color: var(--secondary-color);
	font-size: 20px;
	padding-left: 20px;
	/* font-weight: 650; */
}

.card-body {
	padding-top: 20px;
	font-size: 15px;
}
